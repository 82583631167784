<template>
  <section
    v-if="order"
    class="m-home-order"
  >
    <div class="m-home-order__products">
      <p class="m-home-order__products-num">
        {{ $t('Last order') }}
        <span>№{{ order.number }}</span>
        <span class="m-home-order__products-date">
          {{ $t('from {date}', { date: orderDate }) }}
        </span>
      </p>
      <div class="m-home-order__products-address">
        <p class="m-home-order__products-street">
          {{ order.delivery_address }}
        </p>
      </div>
      <div class="m-home-order__products-row">
        <div class="m-home-order__products-prev">
          <ul class="m-home-order__products-list">
            <li
              v-for="(product, i) in productsVisible"
              :key="i"
              class="m-home-order__products-card"
            >
              <SfImage
                :src="product.sku | productImagePath(220, 220)"
                alt="product"
                class="m-home-order__products-image"
              />
            </li>
          </ul>
          <SfLink
            class="m-home-order__products-count"
            :link="localizedRoute({ path: '/my-account/orders', query: { entityId: order.item_id }})"
            v-if="productsInVisibleCount"
          >
            +{{ productsInVisibleCount }}
          </SfLink>
        </div>
        <div
          v-if="inStockProducts.length"
          class="m-home-order__repeat"
        >
          <MProductsAddToCart
            class="m-home-order__repeat-component"
            :title="$t('Repeat')"
            :products="inStockProducts"
          />
          <svg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.49 25.49A12 12 0 1 1 25.46 8.5 12 12 0 0 1 8.49 25.5Z" fill="#fff" fill-opacity=".5" />
            <path d="M22.63 19.83v-8.49h-8.49M22.63 11.34 11.3 22.66" stroke="#EB6747" />
          </svg>
        </div>
      </div>
    </div>
    <div
      class="m-home-order__action"
      :class="{'m-home-order__action--ukr-store': currentLocale === 'uk-UA'}"
      :style="{ backgroundImage: 'url(/assets/images/main-order.svg)' }"
    >
      <div class="m-home-order__action-link">
        <span>{{ $t('My orders') }}</span>
        <SfLink
          class="sf-link--base m-home-order__link"
          :link="localizedRoute('/my-account/orders')"
        >
          {{ $t('Watch') }}
          <svg width="34" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.49 25.49A12 12 0 1 1 25.46 8.5 12 12 0 0 1 8.49 25.5Z" fill="#fff" fill-opacity=".5" />
            <path d="M22.63 19.83v-8.49h-8.49M22.63 11.34 11.3 22.66" stroke="#EB6747" />
          </svg>
        </SfLink>
      </div>
    </div>
  </section>
</template>

<script>
import { SfLink, SfImage } from '@storefront-ui/vue';
import DeviceType from 'theme/mixins/DeviceType';
import OrderProducts from 'theme/mixins/OrderProducts';
import { prepareOrder } from 'theme/helpers/orders';
import { mapGetters } from 'vuex';
import MProductsAddToCart from 'theme/components/molecules/m-products-add-to-cart';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'MHomeOrder',
  components: {
    SfLink,
    SfImage,
    MProductsAddToCart
  },
  mixins: [DeviceType, OrderProducts],
  data () {
    return {
      actionImage: '/assets/images/main-order.svg'
    }
  },
  computed: {
    ...mapGetters({
      lastOrder: 'user/lastOrder'
    }),
    order () {
      return this.lastOrder ? prepareOrder(this.lastOrder) : null
    },
    orderId () {
      return this.lastOrder ? this.lastOrder.id : null
    },
    productsVisible () {
      if (!this.orderProducts) return []
      if (this.isMobile) {
        return this.orderProducts.slice(0, 3);
      } else if (this.isTablet) {
        return this.orderProducts.slice(0, 5);
      }
      return this.orderProducts.slice(0, 6);
    },
    productsInVisibleCount () {
      return this.orderProducts.length - this.productsVisible.length;
    },
    inStockProducts () {
      return this.existingProducts.filter(e => e.stock?.is_in_stock) || []
    },
    orderDate () {
      if (isNaN(this.order.dateTimeObject.valueOf())) {
        return this.order.date
      } else {
        return this.order.dateTimeObject.toLocaleDateString()
      }
    },
    currentLocale () {
      return currentStoreView().i18n.defaultLocale
    }
  },
  mounted () {
    this.getExistingOrderProducts()
  },
  watch: {
    orderId () {
      this.getExistingOrderProducts()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';
@import "~theme/css/px2rem";

.m-home-order {
  background: #E9F4F9;
  display: flex;
  margin: var(--spacer-23) px2rem(-10) var(--spacer-xl);

  @media (min-width: $tablet-min) {
    margin: var(--spacer-18) 0 var(--spacer-xl) 0;
  }

  @media (min-width: $desktop-min) {
    margin: var(--spacer-2xl) 0;
  }

  &__action{
    padding-top: 0;
    justify-content: center;
    background-position: center var(--spacer-15);
    position: relative;
    display: flex;
    background-repeat: no-repeat;
    background-size: px2rem(85);
    align-items: center;
    width: 40%;
    min-height: px2rem(150);
    box-sizing: border-box;

    @media (min-width: $desktop-min){
      padding: var(--spacer-30);
      background-position: var(--spacer-20) center;
      justify-content: flex-end;
      background-size: 40%;
      width: 33%;
      min-height: auto;
    }

    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      padding: var(--spacer-30) var(--spacer-20) var(--spacer-30) var(--spacer-30);
      background-position: center var(--spacer-30);
      width: 38%;
      background-size: 40%;
      min-height: px2rem(240);
    }

    &--ukr-store {
      @media (min-width: $desktop-min){
        padding: var(--spacer-30);
        background-size: 35%;
        width: 40%;
      }
    }

    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: var(--spacer-20);
      bottom: var(--spacer-20);
      border-left: var(--spacer-3) dotted #fff;
    }

    &-link{
      font-size: var(--font-sm);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: auto;
      padding-bottom: var(--spacer-20);

      @media (max-width: $mobile-max) {
        padding-bottom: var(--spacer-10);
        overflow: hidden;
      }

      .m-home-order__link {
        position: relative;
        left: var(--spacer-5);
        font-size: var(--font-size-14);

        @include for-tablet {
          font-size: var(--font-size-16);
        }

        @include for-desktop {
          font-size: var(--font-size-16);
        }

        @media (min-width: $tablet-min){
          position: static;
        }
      }

      @media (min-width: $tablet-min) and (max-width: $tablet-max){
        margin-top: auto;
        padding-bottom: var(--spacer-5);
      }

      @media (min-width: $desktop-min){
        align-items: baseline;
        margin: auto 0;
        padding-bottom: 0;
      }

      span{
        @media (min-width: $desktop-min){
          font-size: var(--font-xl);
        }

        @media (min-width: $tablet-min) and (max-width: $tablet-max) {
          font-size: 1.313rem;
        }
      }
    }
  }

  &__products{
    width: 60%;
    padding: var(--spacer-15) var(--spacer-5) var(--spacer-5) var(--spacer-15);
    position: relative;
    box-sizing: border-box;

    @media (min-width: $desktop-min) {
      background-position: var(--spacer-20) center;
      justify-content: flex-end;
      width: 60%;
      padding: px2rem(37) var(--spacer-xl);
    }

    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      padding: var(--spacer-30) var(--spacer-20) var(--spacer-10) var(--spacer-30);
      width: 62%;
    }

    &:before, &:after{
      left: auto;
      right: px2rem(-14);
      position: absolute;
    }
    &:before{
      content: '';
      border: var(--spacer-13) solid transparent;
      border-top: var(--spacer-10) solid var(--white);
      top: 0;
    }
    &:after{
      content: '';
      border: var(--spacer-13) solid transparent;
      border-bottom: var(--spacer-10) solid var(--white);
      bottom: 0;
    }
    &-num {
      font-size: var(--font-size-13);
      margin: 0 0 var(--spacer-xs) 0;

      @media (min-width: $tablet-min) {
        font-size: 1.313rem;
        margin: 0 var(--spacer-xs) 0 0;
      }
    }

    &-date {
      font-size: var(--font-base);
      color: var(--dark-gray);
      display: none;

      @media (min-width: $tablet-min) {
        display: inline-block;
      }
    }

    &-row{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &-list{
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
    }

    &-prev{
      display: flex;
    }

    &-card, &-count{
      width: px2rem(46);
      height: px2rem(46);
      border-radius: var(--spacer-5);
      display: inline-flex;
      @media (min-width: $tablet-min){
        width: px2rem(67);
        height: px2rem(67);
        border-radius: var(--spacer-10);
      }
    }

    &-card{
      margin-right: var(--spacer-5);
      background: var(--white);
      @media (min-width: $tablet-min){
        margin-right: var(--spacer-9);
      }
    }

    &-image{
      max-width: px2rem(40);
      margin: auto;

      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        max-width: px2rem(47);
      }

      @include for-desktop {
        max-width: px2rem(47);
      }
    }

    &-count {
      font-size: var(--font-size-13);
      color: var(--orange);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: var(--spacer-25);
      background: rgba(255, 255, 255, .7);
      transition: var(--transition-link);

      @media (min-width: $tablet-min) {
        font-size: var(--font-size-18);
      }

      &:hover {
        color: var(--orange-hover);
      }
    }
  }
  &__products-address {
    display: none;

    @media (min-width: $tablet-min) {
      display: flex;
      align-items: center;
      margin: var(--spacer-10) 0 var(--spacer-20) 0;

      @include font-icon(var(--icon-location));

      &:before {
        font-size: var(--font-size-22);
        color: var(--orange);
      }
    }
  }

  &__products-street {
    font-size: var(--font-sm);
    margin: 0;
    padding-left: var(--spacer-10);
    opacity: .8;
  }

  &__link {
    display: inline-flex;
    align-items: center;

    svg {
      margin-left: var(--spacer-5);
    }
  }

  &__repeat {
    display: inline-flex;
    align-items: center;

    svg {
      margin-left: var(--spacer-5);
    }

    &-component {
      ::v-deep {
        button {
          --button-padding: 0;
          --button-background: transparent;
          --button-color: var(--orange);;
          --button-text-transform: none;
          --button-wrap: normal;
          --button-font-weight: normal;
          --button-font-size: var(--font-size-16);

          @media (max-width: $mobile-max) {
            --button-font-size: var(--font-size-14);
          }

          &:hover {
            --button-box-shadow: 0;
            --button-color: var(--orange-hover);
          }

          &:active {
            --button-background: transparent;
          }
        }

        .sf-button:not(.sf-button--pure):active {
          --button-color: var(--orange);
          --button-background: transparent;
        }
      }
    }
  }
}

::v-deep {

  .sf-icon.size-xxs {
    --icon-size: .85rem;
  }
}
</style>
